import { useMediaQuery, useTheme } from '@mui/material'
import { useCallback, useEffect, useRef } from 'react'

export const useAppScrollLock = (open: boolean) => {
  const theme = useTheme()
  const inMobile = useMediaQuery(theme.breakpoints.down('md'))
  const scrollTop = useRef<number>(0)
  const onUpdateOverflow = useCallback(() => {
    const scrollContainer = document.documentElement

    if (open) {
      scrollTop.current = scrollContainer.scrollTop
      document.body.style.overflow = 'hidden'
      document.body.style.touchAction = 'none'
    }

    if (!open) {
      document.body.style.overflow = ''
      document.body.style.touchAction = ''
      scrollContainer.scrollTop = scrollTop.current
      scrollTop.current = 0
    }
  }, [open])
  useEffect(() => {
    if (inMobile) {
      onUpdateOverflow()
    }
    return () => {
      document.body.style.overflow = ''
      document.body.style.touchAction = ''
    }
  }, [onUpdateOverflow, inMobile])
}
