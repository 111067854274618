import { AutocompleteCollection } from '@algolia/autocomplete-core'
import { Trans } from '@lingui/react'
import { Box, BoxProps, Typography } from '@mui/material'
import { PageHit, AutocompletePageItem } from '../Hits/PageHit'

type collectionProps = {
  collection: AutocompleteCollection<AutocompletePageItem>
  listProps: BoxProps<'ul'>
  getItemProps: any
  handleItemClicked?: () => void
}

export function PagesCollection(props: collectionProps) {
  const { collection, listProps, getItemProps, handleItemClicked } = props
  const { items, source } = collection

  return (
    <Box className='aa-Source'>
      <Typography
        component='h3'
        variant='h6'
        sx={{
          marginBottom: '5px',
        }}
      >
        <Trans id='Pages' />
      </Typography>
      {items.length > 0 ? (
        <Box
          component='ul'
          className='aa-List'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            margin: '0',
            padding: '0',
          }}
          {...listProps}
        >
          {items.map((item) => (
            <PageHit
              key={item.objectID}
              item={item}
              handleItemClicked={handleItemClicked}
              {...getItemProps({
                item,
                source,
              })}
            />
          ))}
        </Box>
      ) : (
        <Box
          sx={(theme) => ({
            padding: {
              xs: theme.spacings.sm,
              md: `0 ${theme.spacings.xxs} ${theme.spacings.xxs}`,
            },
          })}
        >
          <Typography component='em'>
            <Trans id='No results' />
          </Typography>
        </Box>
      )}
    </Box>
  )
}
