import { useCustomerSession } from '@graphcommerce/magento-customer'
import { useStorefrontConfig } from '@graphcommerce/next-ui'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { excludedB2BLoginWallUrls } from './excludedB2BLoginWallUrls'

export function useB2BLoginWall() {
  const { loggedIn } = useCustomerSession()

  const router = useRouter()
  const shouldRedirect =
    useStorefrontConfig().magentoStoreCode.includes('b2b') &&
    loggedIn === false &&
    [router.route, router.asPath].some((url) => excludedB2BLoginWallUrls.includes(url)) === false

  useEffect(() => {
    if (router.asPath !== '/') {
      window.localStorage.setItem('AFTER_LOGIN_REDIRECT_URL', router.asPath)
    }

    // the loggedIn status is retrieved via a client-side hook
    // so initially it will always be false
    // if a customer is actually logged in, value will change from false to true
    // therefore we allow ourselves a 1 second period in which the login status can be retrieved
    // if the customer is actually already logged in, we do not want to redirect
    if (!loggedIn) {
      window.localStorage.setItem('PROCEED_REDIRECT', 'true')
    } else {
      window.localStorage.removeItem('PROCEED_REDIRECT')
    }

    if (shouldRedirect) {
      setTimeout(() => {
        if (window.localStorage.getItem('PROCEED_REDIRECT')) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          router.replace('/account/signin')
        }
      }, 1000)
    }
  }, [loggedIn, router, shouldRedirect])

  return {
    enableLoginWall: shouldRedirect,
  }
}
