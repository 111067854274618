import { Hit } from '@algolia/client-search'
import { useQuery } from '@graphcommerce/graphql'
import { StoreConfigDocument } from '@graphcommerce/magento-store'
import { Box, Link } from '@mui/material'

export type AutocompleteCategoryItem = Hit<{
  url: string
  path: string
  product_count: string
}>

export function CategoryHit(props: {
  item: AutocompleteCategoryItem
  handleItemClicked: () => void
}) {
  const { item, handleItemClicked, ...rest } = props
  const { url, path, product_count } = item
  const { data: config } = useQuery(StoreConfigDocument)

  const storeCode = config?.storeConfig?.store_code
  const urlPath = url.split(`/${storeCode}/`)[1]

  return (
    <Box component='li' sx={{ listStyleType: 'none', display: 'flex' }} {...rest}>
      <Link
        href={`/${urlPath}`}
        onClick={handleItemClicked}
        variant='subtitle2'
        color='inherit'
        underline='none'
        width='100%'
        sx={(theme) => ({
          padding: `5px ${theme.spacings.xxs}`,
          '&:hover': { backgroundColor: '#efefef' },
        })}
      >
        {path} ({product_count})
      </Link>
    </Box>
  )
}
