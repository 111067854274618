import { AutocompleteCollection } from '@algolia/autocomplete-core'
import { Trans } from '@lingui/react'
import { Box, BoxProps, Typography, Divider } from '@mui/material'
import { CategoryHit, AutocompleteCategoryItem } from '../Hits/CategoryHit'

type collectionProps = {
  collection: AutocompleteCollection<AutocompleteCategoryItem>
  listProps: BoxProps<'ul'>
  getItemProps: any
  handleItemClicked?: () => void
}

export function CategoriesCollection(props: collectionProps) {
  const { collection, listProps, getItemProps, handleItemClicked } = props
  const { items, source } = collection

  return (
    <>
      <Divider
        sx={(theme) => ({
          my: theme.spacings.sm,
          [theme.breakpoints.up('md')]: { display: 'none' },
        })}
      />
      <Box className='aa-Source'>
        <Typography
          component='h3'
          variant='h6'
          sx={{
            marginBottom: '5px',
          }}
        >
          <Trans id='Categories' />
        </Typography>
        {items.length > 0 ? (
          <Box
            component='ul'
            className='aa-List'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              margin: '0',
              padding: '0',
            }}
            {...listProps}
          >
            {items.map((item) => (
              <CategoryHit
                key={item.objectID}
                item={item}
                handleItemClicked={handleItemClicked}
                {...getItemProps({
                  item,
                  source,
                })}
              />
            ))}
          </Box>
        ) : (
          <Box
            sx={(theme) => ({
              padding: {
                xs: theme.spacings.sm,
                md: `0 ${theme.spacings.xxs} ${theme.spacings.xxs}`,
              },
            })}
          >
            <Typography component='em'>
              <Trans id='No results' />
            </Typography>
          </Box>
        )}
      </Box>
    </>
  )
}
