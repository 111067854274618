import { Box, Container, Typography } from '@mui/material'
import { StoreNoticeQueryFragment } from './StoreNoticeQueryFragment.gql'

type StoreNoticeProps = StoreNoticeQueryFragment

export function StoreNotice(props: StoreNoticeProps) {
  const { storeNotice } = props
  const content = storeNotice?.content

  return (
    <>
      {content && (
        <Box
          sx={{
            padding: '8px 0',
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',

            '& ::selection': {
              backgroundColor: 'primary.main',
            },
          }}
        >
          <Container>
            <Typography variant='subtitle2' align='center'>
              {content}
            </Typography>
          </Container>
        </Box>
      )}
    </>
  )
}
