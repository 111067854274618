import { Hit } from '@algolia/client-search'
import { Box, Link } from '@mui/material'

export type AutocompletePageItem = Hit<{
  content: string
  name: string
  slug: string
  url: string
}>

export function PageHit(props: { item: AutocompletePageItem; handleItemClicked: () => void }) {
  const { item, handleItemClicked, ...rest } = props
  const { name, slug } = item

  return (
    <Box component='li' sx={{ listStyleType: 'none', display: 'flex' }} {...rest}>
      <Link
        href={`/${slug}`}
        onClick={handleItemClicked}
        variant='subtitle2'
        color='inherit'
        underline='none'
        width='100%'
        sx={(theme) => ({
          padding: `5px ${theme.spacings.xxs}`,
          '&:hover': { backgroundColor: '#efefef' },
        })}
      >
        {name}
      </Link>
    </Box>
  )
}
