export const excludedB2BLoginWallUrls: string[] = [
  '/admin_hjJxyI12a/login-as-customer',
  '/customer/account/createPassword',
  '/account/forgot-password',
  '/become-a-customer',
  '/kunde-werden',
  '/convertirse-en-un-cliente',
  '/devenir-client',
  '/diventare-un-cliente',
  '/klant-worden',
]
