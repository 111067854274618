import { UseNavigationSelection } from '@graphcommerce/next-ui'
import { useMotionValueEvent } from 'framer-motion'

export function useLayoutNavigationScrollTop(props: UseNavigationSelection) {
  const selection = props

  const scrollEffect = () =>
    document
      .querySelector(
        '.Navigation-root > .LayoutOverlayBase-overlay > .LayoutOverlayBase-overlayPane',
      )
      ?.scroll({ behavior: 'smooth', top: 0, left: 0 })

  return useMotionValueEvent(selection, 'change', scrollEffect)
}
