import { useMediaQuery } from '@mui/material'
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'

export function TrustPilotMicroCombo(props: { theme: 'light' | 'dark' }) {
  const { theme } = props
  const { locale } = useRouter()
  const ref = useRef(null)
  const isLargeScreen = useMediaQuery('(min-width:1280px)')

  useEffect(() => {
    if (globalThis.Trustpilot) {
      globalThis.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])

  if (!locale) return null

  const trustPilotLocale =
    locale === 'en' || locale === 'default' ? 'en-US' : `${locale}-${locale.toUpperCase()}`
  const trustPilotHref = `https://${
    locale === 'en' || locale === 'default' ? 'www' : locale
  }.trustpilot.com/review/vaessen-creative.com`

  return (
    <div
      ref={ref}
      className='trustpilot-widget'
      data-locale={trustPilotLocale}
      data-template-id='5419b6ffb0d04a076446a9af'
      data-businessunit-id='5f2fd4a700bf7300017ea736'
      data-style-height='20px'
      data-style-width='100%'
      data-theme={theme}
      data-font-family='Open Sans'
      data-text-color={theme === 'dark' ? '#ffffff' : '##1A1919'}
      style={isLargeScreen ? { width: '420px' } : {}}
    >
      {' '}
      <a href={trustPilotHref} target='_blank' rel='noopener noreferrer'>
        Trustpilot
      </a>{' '}
    </div>
  )
}
