import { IconSvg, iconChevronDown } from '@graphcommerce/next-ui'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useState } from 'react'

export function FooterLinkLists(props: {
  footerLinkLists: (
    | {
        __typename: 'RowLinks'
        title: string
        id: string
        pageLinks: {
          id: string
          url: string
          title: string
        }[]
      }
    | null
    | undefined
  )[]
}) {
  const { footerLinkLists } = props
  const [expandedPanel, setExpandedPanel] = useState<number | null>(null)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Container maxWidth='lg' sx={{ pt: theme.spacings.md }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        {footerLinkLists.map((menu, index: number) => {
          // eslint-disable-next-line react/no-array-index-key
          if (!menu || !menu.pageLinks || menu.pageLinks.length < 1) return <div key={index} />
          const menuItems = menu.pageLinks
          const isPanelExpanded = expandedPanel === index

          return (
            <Accordion
              disableGutters
              elevation={0}
              expanded={isPanelExpanded || !isMobile}
              onChange={() => (isPanelExpanded ? setExpandedPanel(null) : setExpandedPanel(index))}
              sx={{
                background: 'none',
                '& .MuiAccordionSummary-root': { p: 0, cursor: { md: 'default' } },
                '&:before': {
                  display: { md: 'none' },
                },
              }}
              key={menu.id}
            >
              <AccordionSummary
                sx={!isMobile ? { cursor: 'default !important' } : {}}
                expandIcon={
                  <IconSvg
                    size='medium'
                    src={iconChevronDown}
                    sx={{ display: { sm: 'flex', md: 'none', color: '#fff' } }}
                  />
                }
              >
                {menu.title && (
                  <Typography variant='h6' component='div' color='primary.contrastText'>
                    {menu.title}
                  </Typography>
                )}
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0, pb: { xs: theme.spacings.xxs, md: 0 } }}>
                <Box component='ul' sx={{ p: 0, m: 0, listStyle: 'none' }}>
                  {menuItems?.map((link) => (
                    <li key={link.id}>
                      <Link
                        variant='body2'
                        href={link.url}
                        color='primary.contrastText'
                        underline='hover'
                        sx={{ lineHeight: '2.5em' }}
                      >
                        {link.title}
                      </Link>
                    </li>
                  ))}
                </Box>
              </AccordionDetails>
            </Accordion>
          )
        })}
      </Box>
    </Container>
  )
}
