import { useQuery } from '@graphcommerce/graphql'
import { Image } from '@graphcommerce/image'
import { MagentoNavigationItemProps } from '@graphcommerce/magento-category'
import { StoreConfigDocument } from '@graphcommerce/magento-store'
import { Box } from '@mui/material'

export function NavigationItemImage(props: MagentoNavigationItemProps) {
  const { name, level, additional_image } = props

  const { data: config } = useQuery(StoreConfigDocument)
  const baseUrl = config?.storeConfig?.secure_base_url ?? 'https://backend.vaessen-creative.com/'

  if (level !== 2 || !additional_image) return <>{name}</>

  return (
    <Box sx={(theme) => ({ display: 'flex', alignItems: 'center', columnGap: theme.spacings.xs })}>
      <Image
        src={`${baseUrl}${additional_image}`}
        unoptimized
        width={20}
        height={20}
        sx={{ width: 20, height: 20 }}
        alt={`${name} icon`}
      />
      {name}
    </Box>
  )
}
