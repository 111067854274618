import { useCustomerQuery, useCustomerSession } from '@graphcommerce/magento-customer'
import { useStoreData } from '../../lib/hooks/useStoreData'
import {
  UseCustomerProductPricesDocument,
  UseCustomerProductPricesQuery,
} from './UseCustomerProductPrices.gql'

function findSkus<T extends UseCustomerProductPricesQuery['products']>(products: T): string[] {
  return (products?.items ?? []).map((item) => item?.sku).filter(Boolean) as string[]
}

export function useCustomerProductPrices<T extends UseCustomerProductPricesQuery['products']>(
  products: T,
): T {
  const { loggedIn } = useCustomerSession()
  const { mode } = useStoreData()

  // @todo: implement loading/error states?
  // const { data, error, loading } = useCustomerQuery(UseCustomerProductPricesDocument, {
  const { data } = useCustomerQuery(UseCustomerProductPricesDocument, {
    variables: { skus: findSkus(products), pageSize: 100 },
    fetchPolicy: 'cache-and-network',
  })

  const inclTax = mode === 'b2b'

  if (!loggedIn) return products

  const updatedProducts: T = {
    ...products,
    items: products?.items?.map((item) => {
      if (!item) return item

      const currentPriceRange =
        data?.products?.items?.find((i) => i?.sku === item.sku)?.price_range || item.price_range
      const tax = inclTax ? currentPriceRange?.minimum_price.tax.value : 0
      const currentFinalPrice = currentPriceRange?.minimum_price.final_price.value
      const finalPriceWithoutTax =
        tax && currentFinalPrice ? currentFinalPrice - tax : currentFinalPrice

      const finalPriceRange = {
        ...currentPriceRange,
        minimum_price: {
          ...currentPriceRange?.minimum_price,
          final_price: {
            ...currentPriceRange?.minimum_price.final_price,
            value: finalPriceWithoutTax,
          },
        },
      }

      return {
        ...item,
        price_range: finalPriceRange,
      }
    }),
  } as T

  // @todo: implement loading/error states?
  // if (loading || error) {
  // return updatedProducts
  // }

  return updatedProducts
}
