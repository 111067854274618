import { Image } from '@graphcommerce/image'
import { StoreSwitcherButton } from '@graphcommerce/magento-store'
import { Button, Footer as FooterBase, useStorefrontConfig } from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import { IconButton, Link, Box } from '@mui/material'
import { FooterLinkLists } from '../Footer/FooterLinkLists'
import { TrustPilotMicroCombo } from '../TrustPilot/TrustPilotMicroCombo'
import { FooterQueryFragment } from './FooterQueryFragment.gql'

export type FooterProps = FooterQueryFragment

export function Footer(
  props: FooterProps & {
    footerLinkLists?: (
      | {
          __typename: 'RowLinks'
          title: string
          id: string
          pageLinks: {
            id: string
            url: string
            title: string
          }[]
        }
      | null
      | undefined
    )[]
  },
) {
  const { footerLinkLists, footer } = props
  const isB2BStore = useStorefrontConfig().magentoStoreCode.includes('b2b')

  return (
    <Box
      sx={(theme) => ({
        '& ::selection': {
          backgroundColor: theme.palette.text.primary,
          color: theme.palette.primary.contrastText,
        },
        '&::before': {
          width: '100%',
          height: 75,
          background: 'url(/footer-graphics.svg) repeat-x',
          backgroundSize: 850,
          backgroundPosition: 'left bottom',
          content: '""',
          display: 'block',
          position: 'absolute',
          top: -74,
          left: 0,
          [theme.breakpoints.up('md')]: {
            backgroundSize: 'contain',
          },
        },
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        position: 'relative',
        marginTop: `calc(${theme.spacings.xl} + 85px)`,
        '& > :nth-of-type(1)': {
          [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr',
            gridTemplateAreas: `'switcher' 'support' 'social' 'links' 'trustpilot'`,
          },
        },
        '& .Footer-copyright': {
          alignItems: 'center',
          textAlign: 'center',
        },
        '& .Footer-support': {
          display: 'flex',
          gap: theme.spacings.xxs,
        },
      })}
    >
      {footerLinkLists && <FooterLinkLists footerLinkLists={footerLinkLists} />}
      <FooterBase
        sx={{
          borderTop: 'none',
        }}
        socialLinks={
          <>
            {footer?.socialLinks?.map((link) => (
              <IconButton
                key={link.title}
                href={link.url}
                color='inherit'
                size='medium'
                disableRipple
                disableFocusRipple
                edge='start'
              >
                {link.asset ? (
                  <Image
                    layout='fill'
                    src={link.asset.url}
                    width={24}
                    height={24}
                    unoptimized
                    alt={link.title}
                  />
                ) : (
                  link.title
                )}
              </IconButton>
            ))}
          </>
        }
        storeSwitcher={
          <StoreSwitcherButton sx={(theme) => ({ color: theme.palette.primary.contrastText })} />
        }
        customerService={
          <>
            {!isB2BStore && (
              <Button
                href='/service/newsletter'
                variant='contained'
                color='inherit'
                size='medium'
                sx={(theme) => ({
                  color: theme.palette.primary.main,
                  fontFamily: theme.typography.h1.fontFamily,
                  background: theme.palette.background.paper,
                  boxShadow: 4,
                })}
              >
                <Trans id='Newsletter' />
              </Button>
            )}
            <Button
              href='/service'
              variant='contained'
              color='inherit'
              size='medium'
              sx={(theme) => ({
                color: theme.palette.primary.main,
                fontFamily: theme.typography.h1.fontFamily,
                background: theme.palette.background.paper,
                boxShadow: 4,
              })}
            >
              <Trans id='Customer Service' />
            </Button>
          </>
        }
        trustPilot={<TrustPilotMicroCombo theme='dark' />}
        copyright={
          <>
            <span>{footer?.copyright}</span>
            {footer?.legalLinks?.map((link) => (
              <Link
                key={link.title}
                href={link.url}
                underline='hover'
                sx={{ color: 'primary.contrastText' }}
              >
                {link.title}
              </Link>
            ))}
          </>
        }
      />
    </Box>
  )
}
