import { Trans } from '@lingui/react'
import { Box } from '@mui/material'

export function B2BLoginWall() {
  return (
    <Box
      sx={{
        display: 'grid',
        placeContent: 'center',
        textAlign: 'center',
        minHeight: '100vh',
        gap: 2,
      }}
    >
      <p>
        <Trans id='To browse the B2B store you need to be signed in.' />
      </p>
      <p>
        <Trans id='You are being redirected to the login page...' />
      </p>
    </Box>
  )
}
