import { productToGtagItem } from '@graphcommerce/googleanalytics/events/productToGtagItem/productToGtagItem'
import { ProductPageMetaFragment } from '@graphcommerce/magento-product/components/ProductPageMeta/ProductPageMeta.gql'
import { PluginProps } from '@graphcommerce/next-config'
import { useMemoObject } from '@graphcommerce/next-ui'
import { useEffect } from 'react'

export const component = 'ProductPageMeta'
export const exported = '@graphcommerce/magento-product'

function GaSelectPromotion(props: PluginProps<ProductPageMetaFragment>) {
  const { Prev } = props

  const viewItem = useMemoObject({
    promotion_id: 'generic_discount',
    promotion_name: 'Generic discount',
    items: [productToGtagItem(props)],
  })

  useEffect(() => {
    if (viewItem.items[0].discount) {
      globalThis.gtag?.('event', 'select_promotion', viewItem)
    }
  }, [viewItem])

  return <Prev {...props} />
}

export const Plugin = GaSelectPromotion
