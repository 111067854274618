import { Scroller, ScrollerProvider } from '@graphcommerce/framer-scroller'
import { RichText } from '@graphcommerce/graphcms-ui'
import { responsiveVal } from '@graphcommerce/next-ui'
import { Box, Container, SxProps, Theme, useTheme } from '@mui/material'
import { PerformantTrustPilotHeaderWidget } from '../TrustPilot/PerformantTrustPilotHeaderWidget'
import { UspsQueryFragment } from './UspsQueryFragment.gql'

export type ProductUspsProps = UspsQueryFragment & {
  sx?: SxProps<Theme>
  mobile?: boolean
}

export function UspsHorizontalList(props: ProductUspsProps) {
  const { usps, mobile, sx = [] } = props
  const theme = useTheme()

  if (!usps?.uspsMultiple) return null
  const uspsLength = usps.uspsMultiple.length - 1

  return (
    <Container disableGutters maxWidth={false} sx={{ ...(Array.isArray(sx) ? sx : [sx]) }}>
      <ScrollerProvider scrollSnapAlign='end'>
        <Scroller
          hideScrollbar
          sx={[
            !mobile
              ? {
                  gap: theme.spacings.sm,
                  justifyContent: 'left',
                  gridTemplateColumns: `repeat(${uspsLength}, auto) 1fr`,
                  height: '35px',
                  background: '#f8f2f1',
                  px: theme.spacings.md,
                }
              : {
                  gridTemplateRows: '100%',
                  height: 'auto',
                  gridAutoFlow: 'column',
                  gridAutoRows: '100%',
                  gridAutoColumns: '100%',
                },
          ]}
        >
          {usps?.uspsMultiple.map((usp, idx) => {
            if (!usp.description && !usp.asset) return null
            return (
              <Box
                key={usp.title}
                sx={() => ({
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'nowrap',
                  whiteSpace: 'nowrap',
                  gap: 1,
                  '& p': {
                    fontSize: '13px',
                    color: '#707070',
                  },
                  pt: mobile ? '20px' : '0',
                  pb: mobile ? '7px' : '0',
                  ml: !mobile && idx === 0 ? responsiveVal(120, 140) : 0,
                  justifyContent: !mobile ? 'flex-start' : 'center',
                })}
              >
                {usp.asset && (
                  <Box sx={{ flexShrink: 0, display: 'flex', alignItems: 'center' }}>
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img
                      src={usp.asset.url}
                      alt={usp.asset.alt ?? ''}
                      style={{
                        height: 'auto !important',
                        width: '17px',
                      }}
                    />
                  </Box>
                )}
                {usp.description && <RichText raw={usp.description?.raw} />}
              </Box>
            )
          })}
          <Box
            sx={() => ({
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'nowrap',
              whiteSpace: 'nowrap',
              pt: mobile ? '20px' : '0',
              pb: mobile ? '7px' : '0',
              justifyContent: !mobile ? 'flex-start' : 'center',
            })}
          >
            <PerformantTrustPilotHeaderWidget />
          </Box>
        </Scroller>
      </ScrollerProvider>
    </Container>
  )
}
