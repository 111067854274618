import {
  AutocompleteCollection,
  AutocompleteState,
  createAutocomplete,
} from '@algolia/autocomplete-core'
import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia'
import { SearchResponse } from '@algolia/client-search'
import { useQuery } from '@graphcommerce/graphql'
import { SearchDivider } from '@graphcommerce/magento-search'
import { StoreConfigDocument } from '@graphcommerce/magento-store'
import { IconSvg, iconSearch, iconClose } from '@graphcommerce/next-ui'
import { i18n } from '@lingui/core'
import {
  Box,
  BoxProps,
  SxProps,
  Container,
  TextFieldProps,
  Link,
  Modal,
  InputBase,
  Divider,
  IconButton,
  Theme,
} from '@mui/material'
import algoliasearch from 'algoliasearch/lite'
import { useRouter } from 'next/router'
import React from 'react'
import { ProductsCollection, CategoriesCollection, PagesCollection } from './Collections'
import { AutocompleteCategoryItem } from './Hits/CategoryHit'
import { AutocompletePageItem } from './Hits/PageHit'
import { AutocompleteProductItem } from './Hits/ProductHit'
import { useAppScrollLock } from './UseAppScrollLock'

const appId = import.meta.graphCommerce.algoliaApplicationId
const apiKey = import.meta.graphCommerce.algoliaSearchOnlyKey
const searchClient = algoliasearch(appId, apiKey)

type AutocompleteProps = {
  children?: React.ReactNode
  sx?: SxProps<Theme>
  onSearchHitClick: () => void
}

export function Autocomplete(props: AutocompleteProps) {
  const { children, sx = [], onSearchHitClick } = props
  const [modalOpen, setModalOpen] = React.useState(false)
  const [autoCompleteState, setAutoCompleteState] = React.useState<
    AutocompleteState<AutocompleteProductItem | AutocompleteCategoryItem | AutocompletePageItem>
  >({
    collections: [],
    completion: null,
    context: {},
    isOpen: false,
    query: '',
    activeItemId: null,
    status: 'idle',
  })
  const inputRef = React.useRef<HTMLInputElement>(null)
  const formRef = React.useRef<HTMLFormElement>(null)
  const panelRef = React.useRef<HTMLDivElement>(null)

  const router = useRouter()
  const { locale } = useRouter()
  const urlPrefix = locale === 'default' ? 'en' : `/${locale}`

  const { data: config } = useQuery(StoreConfigDocument)
  const storeCode = config?.storeConfig?.store_code ?? 'b2c_en' // this can sometimes be undefined for some reason and cause issues, defaulting to b2c_en is not ideal, but its better than nothing?
  const algoliaIndexProducts = `magento2_${storeCode}_products`
  const algoliaIndexCategories = `magento2_${storeCode}_categories`
  const algoliaIndexPages = `magento2_${storeCode}_pages`

  useAppScrollLock(modalOpen)

  const handleModalOpen = () => {
    setModalOpen(true)
  }

  const handleModalClose = () => {
    setModalOpen(false)
  }

  const handleFormSubmit = () => {
    onSearchHitClick()
    setModalOpen(false)
  }

  const autocomplete = React.useMemo(
    () =>
      createAutocomplete<AutocompleteProductItem | AutocompleteCategoryItem | AutocompletePageItem>(
        {
          id: 'aa-Search',
          placeholder: i18n._(/* i18n */ 'Search for products, categories, ...'),
          onStateChange: ({ state }) => setAutoCompleteState(state),
          getSources: () => [
            {
              sourceId: 'products',
              getItems({ query, setContext }) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [{ indexName: algoliaIndexProducts, query, params: { hitsPerPage: 8 } }],
                  transformResponse({ results, hits }) {
                    setContext({
                      nbProductHits: (results as SearchResponse<AutocompleteProductItem>[])[0]
                        .nbHits,
                    })

                    return hits
                  },
                })
              },
              getItemUrl({ item }) {
                return item.url
              },
            },
            {
              sourceId: 'categories',
              getItems({ query }) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    { indexName: algoliaIndexCategories, query, params: { hitsPerPage: 2 } },
                  ],
                })
              },
              getItemUrl({ item }) {
                return item.url
              },
            },
            {
              sourceId: 'pages',
              getItems({ query }) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [{ indexName: algoliaIndexPages, query, params: { hitsPerPage: 10 } }],
                })
              },
              getItemUrl({ item }) {
                return item.url
              },
            },
          ],
          shouldPanelOpen: (state) => state.state.query.length > 0,
        },
      ),
    [algoliaIndexProducts, algoliaIndexCategories, algoliaIndexPages],
  )

  const { getEnvironmentProps } = autocomplete

  React.useEffect(() => {
    if (!formRef.current || !panelRef.current || !inputRef.current) {
      return undefined
    }

    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { onTouchStart, onTouchMove } = getEnvironmentProps({
      formElement: formRef.current,
      inputElement: inputRef.current,
      panelElement: panelRef.current,
    })

    window.addEventListener('touchstart', onTouchStart)
    window.addEventListener('touchmove', onTouchMove)

    return () => {
      window.removeEventListener('touchstart', onTouchStart)
      window.removeEventListener('touchmove', onTouchMove)
    }
  }, [getEnvironmentProps, formRef, inputRef, panelRef])

  const rootProps = autocomplete.getRootProps({}) as unknown as BoxProps<'div'>
  const formProps = autocomplete.getFormProps({
    inputElement: inputRef.current,
    onSubmit: (e) => {
      e.preventDefault()

      const { query } = autoCompleteState
      const url = `${urlPrefix}/search/${query}`

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      router.push(url)
      handleFormSubmit()
    },
  }) as unknown as BoxProps<'form'>
  const panelProps = autocomplete.getPanelProps({}) as unknown as BoxProps<'div'>
  const inputProps = autocomplete.getInputProps({
    inputElement: inputRef.current,
  }) as unknown as TextFieldProps['inputProps']
  const listProps = autocomplete.getListProps() as BoxProps<'ul'>

  const productsCollection = autoCompleteState.collections.find(
    (collection) => collection.source.sourceId === 'products',
  )
  const categoriesCollection = autoCompleteState.collections.find(
    (collection) => collection.source.sourceId === 'categories',
  )
  const pagesCollection = autoCompleteState.collections.find(
    (collection) => collection.source.sourceId === 'pages',
  )

  return (
    <>
      <Link
        component='button'
        underline='none'
        onClick={(e) => {
          e.preventDefault()
          handleModalOpen()
        }}
        sx={[
          {
            backgroundColor: 'transparent',
            display: 'flex',
            justifySelf: 'center',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: 1,
            borderColor: 'divider',
            borderRadius: 2,
            typography: 'body2',
            color: 'text.secondary',
            width: '100%',
            p: 1.5,
            overflow: 'hidden',
            '&:hover, &:active': {
              backgroundColor: 'transparent',
              borderColor: 'text.secondary',
            },
            '&:focus': {
              backgroundColor: 'transparent',
              borderColor: 'secondary.main',
            },

            '& .SearchLink-text': {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            },
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <Box component='span' className='SearchLink-text' sx={{ flexShrink: '1' }}>
          {children}
        </Box>
        <IconSvg
          src={iconSearch}
          sx={{ color: 'text.primary', fontSize: '1.4em', flexShrink: '0' }}
        />
      </Link>
      <Modal
        open={modalOpen}
        onClose={() => {
          handleModalClose()
        }}
        disablePortal
        disableAutoFocus
        disableEnforceFocus
        sx={{
          width: '100vw',
          height: '100vh',
          '& .MuiBackdrop-root': {
            width: '100vw',
            height: '100vh',
          },
        }}
      >
        <Container
          sx={(theme) => ({
            position: 'relative',

            [theme.breakpoints.down('sm')]: {
              padding: '0',
            },
          })}
        >
          <Box
            className='aa-Autocomplete'
            sx={(theme) => ({
              backgroundColor: 'background.paper',
              borderRadius: { xs: 0, sm: 1 },
              margin: { xs: '0 auto', sm: `${theme.spacings.sm} auto auto` },
              height: { xs: '100%', sm: 'auto' },
              color: theme.palette.text.primary,
              whiteSpace: 'normal',
            })}
            {...rootProps}
          >
            <Box
              component='header'
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                paddingY: theme.spacings.xxs,
              })}
            >
              <Box
                component='form'
                ref={formRef}
                sx={(theme) => ({
                  backgroundColor: '#efefef',
                  flex: '1 1 auto',
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: theme.spacings.xxs,
                })}
                className='aa-Form'
                {...formProps}
              >
                <Box
                  component='label'
                  htmlFor='aa-Search-modal-input'
                  sx={(theme) => ({
                    position: 'initial',
                    transform: 'none',
                    overflow: 'visible',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: theme.spacings.xs,
                    fontSize: '24px',
                    color: 'text.secondary',
                  })}
                >
                  <IconSvg src={iconSearch} size='medium' />
                </Box>
                <Divider orientation='vertical' flexItem sx={{ marginY: 1 }} />
                <InputBase
                  id='aa-Search-modal-input'
                  inputProps={{
                    ...inputProps,
                    type: 'text',
                    autoFocus: true,
                    id: 'aa-Search-modal-input',
                  }}
                  type='text'
                  placeholder={i18n._(/* i18n */ 'Search for products, categories, ...')}
                  fullWidth
                  sx={(theme) => ({ padding: theme.spacings.xxs })}
                  inputRef={inputRef}
                />
              </Box>
              <IconButton
                onClick={handleModalClose}
                size='small'
                sx={(theme) => ({
                  padding: theme.spacings.xxs,
                  marginX: theme.spacings.xxs,
                })}
              >
                <IconSvg src={iconClose} />
              </IconButton>
            </Box>
            {autoCompleteState.isOpen && (
              <Box sx={{ height: '100%', width: '100%' }}>
                <SearchDivider sx={{ margin: '0' }} />
                <Box
                  ref={panelRef}
                  sx={(theme) => ({
                    display: 'flex',
                    flexWrap: { xs: 'wrap', md: 'nowrap' },
                    padding: { xs: theme.spacings.xs, md: '0' },
                    height: '100%',
                    maxHeight: {
                      xs: `calc(var(--client-size-y) - (44px + (${theme.spacings.sm} * 2)))`,
                      sm: `calc(var(--client-size-y) - (100px + (${theme.spacings.sm} * 2)))`,
                    },
                    overflow: 'hidden overlay',
                    overscrollBehavior: 'contain',
                  })}
                  {...panelProps}
                  onTouchMove={() => {
                    inputRef.current?.blur()
                  }}
                >
                  <Box
                    sx={{
                      flex: { xs: '1 0 100%', md: '1 0 70%' },
                      order: { xs: '1', md: '2' },
                    }}
                  >
                    {productsCollection && (
                      <ProductsCollection
                        collection={
                          productsCollection as AutocompleteCollection<AutocompleteProductItem>
                        }
                        listProps={listProps}
                        getItemProps={autocomplete.getItemProps}
                        query={autoCompleteState.query}
                        nbProductHits={autoCompleteState.context.nbProductHits}
                        handleItemClicked={handleFormSubmit}
                      />
                    )}
                  </Box>
                  <Box
                    sx={(theme) => ({
                      flex: { xs: '1 0 100%', md: '0 1 30%' },
                      order: { xs: '2', md: '1' },
                      display: 'flex',
                      flexDirection: 'column',
                      margin: theme.spacings.xxs,
                      gap: theme.spacings.xxs,
                    })}
                  >
                    {categoriesCollection && (
                      <CategoriesCollection
                        collection={
                          categoriesCollection as AutocompleteCollection<AutocompleteCategoryItem>
                        }
                        listProps={listProps}
                        getItemProps={autocomplete.getItemProps}
                        handleItemClicked={handleFormSubmit}
                      />
                    )}

                    {pagesCollection && (
                      <PagesCollection
                        collection={pagesCollection as AutocompleteCollection<AutocompletePageItem>}
                        listProps={listProps}
                        getItemProps={autocomplete.getItemProps}
                        handleItemClicked={handleFormSubmit}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Container>
      </Modal>
    </>
  )
}
