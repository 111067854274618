/* eslint-disable */
import * as Types from '@graphcommerce/graphql-mesh/.mesh';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

export const AudienceDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Audience"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"customer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"audience"}}]}}]}}]} as unknown as DocumentNode<AudienceQuery, AudienceQueryVariables>;
export type AudienceQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AudienceQuery = { customer?: { audience?: Types.AudienceEnum | null } | null };
