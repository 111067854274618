import { Logo as LogoBase } from '@graphcommerce/next-ui'
import { SxProps, Theme } from '@mui/material'
import svgLogo from './vaessen.svg'

export function Logo(props: { sx?: SxProps<Theme> }) {
  const { sx = {} } = props
  return (
    <LogoBase
      sx={{
        alignSelf: 'start',
        height: { xs: '60px', md: '110px' },
        zIndex: '999',

        '& .GcLogo-logo': {
          width: 'auto',
          height: { xs: '60px', md: '110px' },
          paddingLeft: { xs: 0, md: 0 },
        },
        ...sx,
      }}
      image={{ alt: 'Vaessen Logo', src: svgLogo, unoptimized: true }}
    />
  )
}
